jQuery(document).ready(function () {
    var body = $('body'),  phone,
        jqFormXHR = null;

    $('.header_menu').swipe( {
        swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
            $(this).removeClass('in');
        },
        threshold:30
    });

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if(iOS){
        $('.sign_in_up').css('position','absolute');
        $('.modal').css('position','absolute');
    }

    body.on('click touch', '.js-proxy-link', function (e) {
        e.preventDefault();

        var popup = $('.js-proxy-popup');

        popup.find('.js-proxy-popup-button')
            .attr('href', $(this).data('url'));
        popup.find('.js-proxy-popup-title')
            .html($(this).data('title'));
        popup.find('.js-proxy-popup-desc')
            .html($(this).data('text'));

        switch ($(this).data('action')) {
            case 'close':
                popup.find('.js-proxy-popup-button')
                    .addClass('js-popup-close');
                break;
            default:
        }

        popup.fadeToggle('slow');
    });

    body.on('click touch', '.js-proxy-question-link', function (e) {
        e.preventDefault();
        var popup = $('.js-proxy-question-popup');

        popup.find('.js-proxy-popup-submit')
            .attr('href', $(this).data('url'));
        popup.find('.js-proxy-popup-title')
            .html($(this).data('title'));
        popup.find('.js-proxy-popup-desc')
            .html($(this).data('text'));

        popup.fadeToggle('slow');
    });

    body.on('click touch', '.js-proxy-access-denied-link', function (e) {
        e.preventDefault();
        var popup = $('.js-proxy-access-denied-popup');

        popup.find('.js-proxy-popup-submit')
            .attr('href', $(this).data('url'));
        popup.find('.js-proxy-popup-title')
            .html($(this).data('title'));
        popup.find('.js-proxy-popup-desc')
            .html($(this).data('text'));

        popup.fadeToggle('slow');
    });

    body.on('click touch', '.js-proxy-loyal-link', function (e) {
        e.preventDefault();
        var popup = $('.js-proxy-loyal-popup');

        popup.find('.js-proxy-popup-submit')
            .attr('href', $(this).data('url'));
        popup.find('.js-proxy-popup-title')
            .html($(this).data('title'));
        popup.find('.js-proxy-popup-desc')
            .html($(this).data('text'));

        popup.fadeToggle('slow');
    });

    body.on('click touch', '.modalx .clmodal', function (e) {
        var popup = $(this).closest('.modalx');
        popup.css('opacity', '0');
        setTimeout(function(){
            popup.removeAttr('style');
        },500)
    });

    body.on('click touchstart touch', '.wrap-popup .closed, .js-popup-hide', function (e) {
        $('.section-popup-message').fadeOut('slow');
    });

    body.on('submit', '.ajax-form', function () {
        var form = $(this);
        form.ajaxSubmit({
            beforeSend: function (_jqFormXHR) {
                if (jqFormXHR != null && jqFormXHR.hasOwnProperty('abort')) {
                    jqFormXHR.abort();
                }

                form.find('.btn').attr('disabled', true)
                preloader.show()
                jqFormXHR = _jqFormXHR;
            },
            success: function (data) {
                $('.preloader').addClass('fade');
                preloader.hide()
                if (data === 'reload') {
                    window.location.reload();
                    return;
                }
                var redirectUrl = isJson(data);

                if (redirectUrl){
                    if (redirectUrl.url === 'reload') {
                        window.location.reload();
                        return;
                    }
                    window.location.replace(redirectUrl.url);
                    return;
                }

                var content = $(data);
                content.css('display', 'block');
                form.closest('.section-popup')
                    .replaceWith(content);

                content
                    .find('.js-phone')
                    .trigger('keyup');
            }
        });

        return false;
    });

    function isJson(str){
        try{
            return JSON.parse(str);
        }catch (e) {
            return false;
        }
    }

    $('.js-toggle-button-cont').toggleButton();
    body.on('click', '.js-toggle-target-button', function () {
        var target = $(this).data('target'),
            toggleButton = target && $(target);

        if (toggleButton && toggleButton.length > 0) {
            toggleButton.click();
        }

        return false;
    });

    $('.show-more-button').on('click', function(e) {
        e.preventDefault();
        var target = $(this).closest('.search-category')
            .find('.search-section');
        var self = $(this);
        var countElement = self.closest('.show-more-holder').find('.items-displayed');
        var totalValue = Number(self.closest('.show-more-holder').find('.items-total').text());
        if (self.data('finished')) {
            return;
        }

        if (!$(this).data('page')) {
            $(this).data('page', 2);
        }

        var page = $(this).data('page');
        var url = $(this).data('url').replace('/2?', '/' + page + '?');
        $(this).data('page', page + 1);

        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'html'
        }).done(function (data) {
            if (data.trim() == '') {
                // no items come
                self.remove();
                return;
            }
            target.append(data);
            var itemsText = countElement.text().split(" ");
            var itemsCount = Number(itemsText.shift());

            itemsCount += $(data).filter('.carousel-cell').length;
            countElement.text(itemsCount + " " + itemsText.join(" "));

            if (itemsCount === totalValue) {
                // all items loaded
                self.remove();
            }
        });
    });

    body.on('click', '.btn-link-hk', function () {
        window.location = $(this).data('url');
        return false;
    });

    var owl1 = $('.carousel-1'),
        owl2 = $('.carousel-2'),
        // buttonCat = $('.mts-category .mts-category-button'),
        navMenuBut = $('.menu-btn'),
        navMenuUl = $('.nav-menu-bar'),
        popupLogin = $('.login-room'),
        popupLoginEnter = $('.login-site'),
        popupButRemember = $('.login-room input[type="submit"]');

    navMenuBut.click(function () {
        $(this).toggleClass('active');
        navMenuUl.slideToggle('slow');
    });

    body.on('click', '.link-login', function () {
        if ($('.login-site').length > 0) {
            showLoginPopup();
        } else {
            $.ajax({
                url: $(this).data('route'),
                method: 'POST'
            }).done(function (data) {
                $('.section-popup:visible').hide();
                body.append(data);
                showLoginPopup();
            });
        }
    });

    function showLoginPopup() {
        var loginPopup = $('.login-site');
        $('.section-popup:visible').hide();
        loginPopup.fadeIn('slow');
        var phoneInput = loginPopup.find('.phone');

        if (typeof phoneInput.data('mask') !== 'object') {
            loginPopup.find('.phone').mask(phoneInput.data('mask') || '+7-(000)-000-00-00');
        }
    }

    popupButRemember.click(function () {
        popupLogin.fadeOut('slow');
        popupLoginEnter.fadeIn('slow');
    });

    body.on('click', '.wrap-popup .closed', function () {
        $('.section-popup').fadeOut('slow');
    });

    // buttonCat.click(function () {
    //     $(this).children('.container').find('button').toggleClass('active');
    //     $(this).next('.container').slideToggle('slow');
    // });

    if($('.item-content').length > 0){
        var itemH5 = $('.item-content h5'),
            itemParagraph = $('.item-content p');

        itemH5.click(function () {
            $(this).toggleClass('active');
            itemParagraph.slideToggle('slow');
        });
    }

    $('.link-size a').click(function () {
        $('.block-bookmark').slideUp('slow');
        $('.block-font-settings').slideToggle('slow');
    });
    $('.dark').on('mouseup touchend', function () {
        $('.dark, .white').removeClass('active');
        $('.content-book, .read-setting, .dark').addClass('active');
    });
    $('.white').on('mouseup touchend', function () {
        $('.dark, .white, .content-book, .read-setting').removeClass('active');
        $(this).toggleClass('active');
    });

    $('.link-settings a').click(function () {
        $('.block-font-settings').slideUp('slow');
        $('.block-bookmark').slideToggle('slow');
    });

    $('.block-bookmark .block-title .chapter').click(function () {
        $('.block-bookmark .bookmark').removeClass('active');
        $(this).addClass('active');
        $('.block-bookmark .block-ul .chapter').fadeIn(600);
    });

    $('.block-bookmark .block-title .bookmark').click(function () {
        $('.block-bookmark .chapter').removeClass('active');
        $(this).addClass('active');
        $('.block-bookmark .block-ul .bookmark').fadeIn(600);
    });

    // $('.link-search a').click(function () {
    //     $('.block-search').slideDown('slow');
    // });
    //


    /*room*/

    $('.status-button, .active-subscription').click(function () {
        $(this).toggleClass('active');
        $(this).children().toggleClass('active');
        $('.subscription-everyday').toggleClass('active');
        if($('.active-subscription').text() == 'Активировать'){
            $('.active-subscription').text('Активировано');
        } else{
            $('.active-subscription').text('Активировать');
        }
    });

    $('.filter-menu li').click(function () {
        $(this).find('.status-button').toggleClass('active');
        $(this).find('.toggle-button').toggleClass('active');
    });

    $('.filter-menu .status-button').click(function () {
        $(this).toggleClass('active');
        $(this).children('.toggle-button').toggleClass('active');
    });


    $('.block-images').click(function () {
        $('.error-site').fadeToggle('slow');
    });

    $('.menu-list').click(function () {
        $('.audioplayer .playlist').slideToggle('slow');
    });

    $('.butt-play-pause').click(function () {
       $(this).toggleClass('active');
    });

    var phoneInput =  $('.js-phone');

    masked(body,phone);

    body.on('click touch', '.js-login', function (e) {
        var loginPopup = $('.js-popup-login'),
            loginPopupSource = $('.js-login-popup-source');
        loginPopup.html(loginPopupSource.html());
        var autologin =  $('#hidden_phone_login_form_continue')

        var contentRedirect = $(this).data('id')
        var isIos = $(this).data('ios')
        var landRedirect = $('input[name="phone_login_form[landingId]"]')
        var hiddenLandRedirect = $('input[name="hidden_phone_login_form[landingId]"]')
        landRedirect.val(contentRedirect)
        hiddenLandRedirect.val(contentRedirect)
        !isIos && autologin.length>0 && $('.ajax-form').first().submit();
        (autologin.length == 0 || isIos) && showFullScreenPopup(loginPopup);

        masked(body, phone);
        return false;
    });

    body.on('click touch', '.js-landing', function (e) {
        var loginPopup = $('.js-popup-login'),
            loginPopupSource = $('.js-landing-popup-source');
        loginPopup.html(loginPopupSource.html());
        var landImg = e.currentTarget.dataset.img
        if (landImg && typeof loginPopup.find('img')[0] !== 'undefined') {
            loginPopup.find('img')[0].src = landImg
        }
        var contentRedirect = $(this).data('id')
        var isIos = $(this).data('ios')
        var landRedirect = $('input[name="phone_login_form[landingId]"]')
        var hiddenLandRedirect = $('input[name="hidden_phone_login_form[landingId]"]')
        landRedirect.val(contentRedirect)
        hiddenLandRedirect.val(contentRedirect)
        console.log(!isIos)
        var autologin =  $('#hidden_phone_login_form_continue')
        !isIos && autologin.length>0 && $('.ajax-form').first().submit();
        (autologin.length == 0 || isIos) && showFullScreenPopup(loginPopup);

        masked(body, phone);
        return false;
    });

    $( document ).ajaxSuccess(function () {
        masked(body, phone);
        if(iOS){
            $('.sign_in_up').css('position','absolute');
        }
    });
    body.change(function () {
            phone =body.find('.js-phone').val();
            masked(body,phone);
        });

        body.on('keyup', '.js-phone', function () {
        $('.js-phone-disabled').prop('disabled', !$(this).val().replace(/ /g,"").match( /\+375\d{9}/g)); // TODO Валидация всей формы
    });

    body.on('click', '.js-search', function () {
        var loginPopup = $('.js-popup-search');
        showFullScreenPopup(loginPopup);

        return false;
    });

    $('.cartoon_img').on('click', function () {
        var video = $('video')[0];
        if(video!=undefined) {
            video.paused ? video.play() : video.pause();
        }
    } );

    $('.right_txt').on('click',function () {
        var item = $(this).children('.description-item');
        item.toggleClass('active');
    });

});

function showPopup(popup, url, title, text) {

    popup.find('.js-proxy-popup-submit')
        .attr('href', url);
    popup.find('.js-proxy-popup-title')
        .html(title);
    popup.find('.js-proxy-popup-desc')
        .html(text);

    popup.fadeToggle('slow');
}

function showFullScreenPopup(element) {
    var body = $('body');
    var footer = $('footer')
    var wrapper = $('.wrapper')
    element.fadeIn('slow').css('display', 'flex');
    body.addClass('popup-displayed');
    footer.addClass('popup-displayed');
    wrapper.addClass('popup-displayed');
    $(window).scrollTop(0);

    element.on('click', '.close-btn', function () {
        $(this)
            .closest('.js-popup')
            .fadeOut('slow');
        body.removeClass('popup-displayed');
        footer.removeClass('popup-displayed');
        wrapper.removeClass('popup-displayed');
        return false;
    });
}

function masked(body,phone) {
    var keyCode;

   if(phone){
       $( document ).ajaxSuccess(function () {
           body.find('.js-phone').val(phone)
       });
   }
   else{
       phone = body.find('.js-phone').val()?phoneCorection(body.find('.js-phone').val()):null;
       body.find('.js-phone').val(phone);
   }

   body.trigger('keyup').on('focus','.js-phone',function(){$(this).val('+375 ')});
    body.on('keydown','.js-phone', function (e) {
        if($(this).attr('maxlength')==12){
            $(this).attr('maxlength',15);
        }
        keyCode = e.keyCode;
    });

    body.find('.js-phone') .bind('focus', function (e) {
        $(this).attr('maxlength',17);
    });
    body.find('.js-phone') .bind('input', function (e) {
        $(this).attr('maxlength',17);
        if($(this).val().length<=5 && e.keyCode==8){
            return false;
        }

        if($(this).val().slice(0,5)!='+375 '){
            $(this).val('+375 ');
        }

        if(e.originalEvent.data!=null&&e.originalEvent.data.match(/\d/)==null){
            if(keyCode!=8){
                if($(this).val().length<17) {
                    $(this).val($(this).val().slice(0, -1));
                }
            }
        }

        if($(this).val().length ==7 ||$(this).val().length== 11 || $(this).val().length== 14 ){
            $(this).val($(this).val()+' ') ;
        }

        if(keyCode==8){
            if($(this).val().length ==8 ||$(this).val().length== 12 || $(this).val().length== 15 ){
                $(this).val($(this).val().slice(0,-1)) ;
            }
        }
        if($(this).val().replace(/ /g,"").length>=14 ){
            $(this).val($(this).val().slice(0,-1)) ;
        }
    });
}

function phoneCorection(phone) {
    phone = '+'+phone;
    phone = phone.slice(0, 4) + ' ' + phone.slice(4);
    phone = phone.slice(0, 7) + ' ' + phone.slice(7);
    phone = phone.slice(0, 11) + ' ' + phone.slice(11);
    phone = phone.slice(0, 14) + ' ' + phone.slice(14);
    return phone;
}

var preloader={
    show: function () {
        var preloader = $('.preloader')
        preloader.show();
        preloader.removeClass('fade');
    },
    hide:function () {
        var preloader = $('.preloader')
        preloader.addClass('fade');
        setTimeout(function () {
            preloader.hide()
        },300)
    }
}
